import {useEffect} from 'react';
import {MsalProvider, useMsal} from '@azure/msal-react';

import {Route, Routes} from "react-router-dom";
import {Home} from './pages/Home';


import './styles/App.css';
import {NavigationBar} from "./components/NavigationBar";

const Pages = () => {
    const {instance} = useMsal();
    useEffect(() => {
        const callbackId = instance.addEventCallback(() => {
        });
        return () => {
            if (callbackId) {
                instance.removeEventCallback(callbackId);
            }
        };
        // eslint-disable-next-line
    }, [instance]);

    return (<Routes>
        <Route path="/" element={<Home/>}/>
    </Routes>);
};


const App = ({instance}) => {
    return (
        <MsalProvider instance={instance}>

            <Pages/>
            <NavigationBar/>
        </MsalProvider>
    );
};

export default App;
