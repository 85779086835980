import {Button} from 'react-bootstrap'
import {AuthenticatedTemplate, UnauthenticatedTemplate, useMsal} from '@azure/msal-react'
import {loginRequest} from '../authConfig'
import {Hinweis} from "./Hinweis"
import {useState} from 'react';

export const NavigationBar = () => {
    const {instance} = useMsal()
    const [modalShow, setModalShow] = useState(true);
    const handleLoginRedirect = () => instance.loginRedirect(loginRequest).catch((error) => console.log(error))

    const handleLogoutRedirect = () => instance.logoutRedirect().then()

    return (
        <>
            <AuthenticatedTemplate>
                <Button variant="danger" onClick={handleLogoutRedirect} className="navbarButton">
                    Abmelden
                </Button>
                <Button variant="primary" onClick={() => setModalShow(true)} className="noticeButton">
                    Datenschutzhinweis
                </Button>
                <Hinweis show={modalShow} onHide={() => setModalShow(false)}/>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Button variant="info" onClick={handleLoginRedirect} className="navbarButton">
                    Login
                </Button>
            </UnauthenticatedTemplate>
        </>
    )
}