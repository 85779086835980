import { useEffect, useState } from 'react';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Spinner from 'react-bootstrap/Spinner';
import { ErrorMessage } from "../components/ErrorMessage"
import { apiEndpoint, loginRequest } from "../authConfig";
import useFetchWithMsal from '../hooks/useFetchWithMsal';
import { PowerBI } from "../components/PowerBI";


/**
 * The `MsalAuthenticationTemplate` component will render its children if a user is authenticated
 * or attempt to sign a user in. Just provide it with the interaction type you would like to use
 * (redirect or popup) and optionally a request object to be passed to the login API, a component to display while
 * authentication is in progress or a component to display if an error occurs. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const Home = () => {
    const { error, execute } = useFetchWithMsal(loginRequest);
    const [embedParams, setEmbedParams] = useState(null);
    
    useEffect(() => {
        if (!embedParams) {
            execute("GET", apiEndpoint.embedTokenAPI).then((response) => {
                if (response) {
                    setEmbedParams(response)
                }
            });
        }
    }, [execute, embedParams])

    if (error) return <ErrorMessage message={error.message} />

    return (
        embedParams ?
            <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={loginRequest}>
                {<PowerBI embedParams={embedParams} />}
            </MsalAuthenticationTemplate>
            :
            <Spinner animation="border" role="status" className="loadingSpinner" />
    )
};





