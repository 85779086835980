import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Badge from 'react-bootstrap/Badge';
import Accordion from 'react-bootstrap/Accordion';

export const Hinweis = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Bitte akzeptieren Sie die Nutzungsbedingungen bevor Sie fortfahren
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Wir weisen Sie darauf hin, dass eine Weitergabe des Dashboards und der verwendeten Daten an nicht autorisierte Dritte zu unterbleiben hat.
        </p>
        <p>
          Bitte beachten Sie in jedem Fall die TLP Version 2 Klassifizierungen, welche hier beschrieben sind. Im Migrations-Dashboard wird aktuell die Klassifizierung TLP:AMBER+STRICT verwendet.
        </p>
        <p>
          Durch die Nutzung des Dashboards erkläre ich mich mit der Verarbeitung meiner Daten, beispielsweise zum Zweck der sicheren Anmeldung, sowie einer aggregierten Nutzungsstatistik, einverstanden. Ich bin darüber informiert, dass ich meine Einwilligung jederzeit widerrufen kann. Die
          Rechtmäßigkeit der aufgrund der jeweiligen Einwilligung bis zum Widerruf erfolgten Verarbeitung
          bleibt unberührt.
        </p>
        <h4>
          TLP-Daten-Klassifizierungen
        </h4>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              <Badge pill bg="dark" text="warning">
                TLP:AMBER+STRICT
              </Badge>
              &nbsp;
              <Badge pill bg="success" >
                Aktuelle Klassifizierung
              </Badge>
            </Accordion.Header>
            <Accordion.Body>
              <h5>Eingeschränkte interne Weitergabe </h5>
              Die Einstufung von Informationen als TLP:AMBER+STRICT beschränkt die Weitergabe ausschließlich auf die Organisation des Empfängers, jegliche Weitergabe darüber hinaus ist untersagt. Es gilt „Kenntnis nur, wenn nötig“. Der Informationsersteller kann weitergehende
              oder zusätzliche Einschränkungen der Informationsweitergabe festlegen. Diese müssen eingehalten werden.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>
              <Badge pill bg="dark">
                TLP:WHITE
              </Badge>
            </Accordion.Header>
            <Accordion.Body>
              <h5>Unbegrenzte Weitergabe </h5>
              Abgesehen von urheberrechtlichen Aspekten, dürfen Informationen der Stufe TLP:WHITE ohne Einschränkungen frei weitergegeben werden.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>
              <Badge pill bg="dark" text="success">
                TLP:GREEN
              </Badge>
            </Accordion.Header>
            <Accordion.Body>
              <h5>Organisationsübergreifende Weitergabe </h5>
              Informationen dieser Stufe dürfen innerhalb der Organisationen und an deren Partner frei weitergegeben werden. Die Informationen dürfen jedoch nicht veröffentlicht werden.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="3">
            <Accordion.Header>
              <Badge pill bg="dark" text="warning">
                TLP:AMBER
              </Badge>
            </Accordion.Header>
            <Accordion.Body>
              <h5>Eingeschränkte interne und organisationsübergreifende Weitergabe</h5>
              Informationen dieser Stufe darf der Empfänger innerhalb seiner Organisation auf Basis "Kenntnis nur wenn nötig" weitergeben.
              Der Empfänger darf die Informationen zudem an Dritte weitergeben, soweit diese die Informationen zum Schutz des Empfängers oder zur Schadensreduktion beim
              Empfänger benötigen. Hierfür muss er sicherstellen, dass die „Dritten“ das TLP kennen und die damit verbundenen Regeln einhalten.
              Der Informationsersteller kann weitergehende oder zusätzliche Einschränkungen der Informationsweitergabe festlegen, diese müssen eingehalten werden.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="4">
            <Accordion.Header>
              <Badge pill bg="dark" text="danger">
                TLP:RED
              </Badge>
            </Accordion.Header>
            <Accordion.Body>
              <h5>Persönlich, nur für bekannte Empfänger</h5>
              Informationen dieser Stufe sind auf den Kreis der Anwesenden in einer Besprechung oder Video-/
              Audiokonferenz bzw. auf die direkten Empfänger bei schriftlicher Korrespondenz beschränkt. Eine
              Weitergabe ist untersagt. Meistens werden TLP:RED-Informationen mündlich oder persönlich übergeben
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" onClick={props.onHide}>Akzeptieren</Button>
      </Modal.Footer>
    </Modal>
  );
}

