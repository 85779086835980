import {PowerBIEmbed} from 'powerbi-client-react';
import {models} from "powerbi-client"

export const PowerBI = (props) =>
    <PowerBIEmbed
        embedConfig={{
            type: "report",
            id: props.embedParams.reportId,
            embedUrl: props.embedParams.embedUrl,
            accessToken: props.embedParams.accessToken,
            tokenType: models.TokenType.Embed,
        }}
        cssClassName={"report-style-class"}
    />

