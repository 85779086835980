import Alert from 'react-bootstrap/Alert';

export const ErrorMessage = (props) => (
  <Alert variant="danger">
    <Alert.Heading>Fehler</Alert.Heading>
    <p>
      Bitte laden Sie die Seite neu und melden Sie sich erneut an um das Problem zu beheben. <br />
      Falls dies nicht weiterhelfen sollte, kann ein Neustart oder ein Löschen vom Browsercache das Problem ggf. beheben.
      Alternativ wird empfohlen ein anderes Gerät für die Nutzung des Dashboards zu verwenden.
    </p>
    <hr />
    <p>Details: {props.message}</p>
  </Alert>
);


